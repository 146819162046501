import { IForm } from '@/base-ui/form'

export const registerFormConfig: IForm = {
  formItems: [
    {
      field: 'firstName',
      type: 'input',
      title: 'firstName',
      placeholder: 'firstName',
      width: '215px'
    },
    {
      field: 'lastName',
      type: 'input',
      title: 'lastName',
      placeholder: 'lastName',
      width: '215px'
    },
    {
      field: 'birthday',
      type: 'datepicker',
      title: 'birthday',
      placeholder: '',
      width: '215px'
    },
    {
      field: 'identity',
      type: 'select',
      title: 'identity',
      placeholder: ' ',
      width: '215px'
    },
    {
      field: 'title',
      type: 'select',
      title: 'position',
      placeholder: ' ',
      width: '440px'
    }
  ],
  titleWidth: '215px',
  itemStyle: { margin: '10px 0' },
  colLayout: { span: 12 }
}

export const registerFormBConfig: IForm = {
  formItems: [
    {
      field: 'country',
      type: 'select',
      title: 'country',
      placeholder: ' ',
      width: '440px'
    }
  ],
  titleWidth: '440px',
  itemStyle: { margin: '10px 0' },
  colLayout: { span: 24 }
}

export const registerFormModule: IForm = {
  formItems: [
    {
      field: 'email',
      type: 'input',
      title: 'email',
      placeholder: 'email',
      width: '440px'
    },
    {
      field: 'code',
      type: 'verification',
      title: 'verificationCode',
      placeholder: 'verificationCode',
      width: '440px'
    },
    {
      field: 'password',
      type: 'password',
      title: 'password',
      placeholder: 'password',
      width: '440px'
    },
    {
      field: 'checkPassword',
      type: 'password',
      title: 'confirPassword',
      placeholder: 'confirPassword',
      width: '440px'
    }
  ],
  titleWidth: '440px',
  itemStyle: { margin: '10px 0' },
  colLayout: { span: 24 }
}
