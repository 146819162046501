
import { defineComponent } from 'vue'
import NavHeader from '@/components/nav-header'
import RegisterInfo from './cpns/register-info.vue'

export default defineComponent({
  components: {
    NavHeader,
    RegisterInfo
  },
  setup() {
    return {}
  }
})
