
import { defineComponent, ref, computed } from 'vue'
import {
  registerFormConfig,
  registerFormBConfig,
  registerFormModule
} from '../config/register-form.config'
import '@/assets/css/index.less'
import router from '@/router'
import hhRequest from '@/service'
import store from '@/store'
import { formatTimeWithString } from '@/utils/data-format'
import { useCurrentInstance } from '@/utils/public-methods'

export default defineComponent({
  setup() {
    const gender = ref('MALE')

    const { proxy } = useCurrentInstance()
    // 获取下拉菜单
    let countryObject: any[] = []
    const countries: any[] = []
    async function getCountries() {
      await hhRequest
        .get({
          url: '/data/country'
        })
        .then((res) => {
          countryObject = res
          for (const item of countryObject) {
            const dict = { title: '', value: 0 }
            dict.title = (item as any).name
            dict.value = (item as any).code
            countries.push(dict)
          }
        })
    }

    let identityObject: any[] = []
    const identities: any[] = []
    async function getIdentities() {
      await hhRequest.get({ url: '/data/identity' }).then((res) => {
        identityObject = res
        for (const item of identityObject) {
          const dict = { title: '', value: 0 }
          dict.title = (item as any).name
          dict.value = (item as any).code
          identities.push(dict)
        }
      })
    }

    let titleObject: any[] = []
    const titles: any[] = []
    async function getTitles() {
      await hhRequest.get({ url: '/data/title' }).then((res) => {
        titleObject = res
        for (const item of titleObject) {
          const dict = { title: '', value: 0 }
          dict.title = (item as any).name
          dict.value = (item as any).code
          titles.push(dict)
        }
      })
    }
    getCountries()
    getIdentities()
    getTitles()

    const formItems = registerFormConfig?.formItems ?? []
    const formBItems = registerFormBConfig?.formItems ?? []
    const formModule = registerFormModule?.formItems ?? []

    const formOriginData: any = {}
    const formBOriginData: any = {}
    const formModuleData: any = {}

    for (const item of formItems) {
      formOriginData[item.field] = ''
      if (item.title === 'identity') {
        item.options = identities
      }
      if (item.title === 'position') {
        item.options = titles
      }
    }
    for (const item of formBItems) {
      formBOriginData[item.field] = ''
      if (item.title === 'country') {
        item.options = countries
      }
    }
    for (const item of formModule) {
      formModuleData[item.field] = ''
      // 判断邮箱的有效性
      if (item.field === 'email') {
        item.rules?.forEach((value, index) => {
          if (index === 0) {
            value.message = proxy?.$filters.hh_t('mustEmail')
          } else {
            value.message = proxy?.$filters.hh_t('enterValidEmail')
          }
        })
      }
      // 密码规则设定
      if (item.field === 'password') {
        item.rules?.forEach((value, index) => {
          if (index === 0) {
            value.message = proxy?.$filters.hh_t('passwordNotEmpty')
          } else {
            value.message = proxy?.$filters.hh_t('passwordRule')
          }
        })
      }
      if (item.field === 'checkPassword') {
        item.rules?.forEach((value, index) => {
          if (index === 0) {
            value.message = proxy?.$filters.hh_t('enterPasswordAgain')
          } else {
            value.validator = passwordtoMatch
            value.message = proxy?.$filters.hh_t('passwordNotMatch')
          }
        })
      }
    }

    const formData = ref(formOriginData)
    const formBData = ref(formBOriginData)
    const formMData = ref(formModuleData)

    function passwordtoMatch(rule: string, value: string) {
      if (value !== formMData.value.password) {
        return false
      } else {
        return true
      }
    }

    const isAgree = ref(true)
    const isModuleOne = ref(true)
    const nextAction = () => {
      if (isAgree.value) {
        isModuleOne.value = false
      }
    }

    // 判断是否进入下一页
    const nextDisabled = computed(() => {
      return !(
        formData.value.firstName &&
        formData.value.lastName &&
        formData.value.birthday &&
        formData.value.identity &&
        formData.value.title &&
        formBData.value.country
      )
    })
    // 判断是否可以注册
    const registerDisabled = computed(() => {
      return !(
        formMData.value.email &&
        formMData.value.code &&
        formMData.value.password &&
        formMData.value.checkPassword
      )
    })

    // 提交数据

    const agreementClick = () => {
      console.log('hh=======register')
    }

    const registerAction = () => {
      // const date: string = formData.value.birthday
      const date = formData.value.birthday
      const birthday = formatTimeWithString(date)
      const submitData = {
        address: {
          country: formBData.value.country
        },
        birthday: birthday,
        email: formMData.value.email,
        identity: formData.value.identity,
        name: {
          firstName: formData.value.firstName,
          lastName: formData.value.lastName
        },
        password: formMData.value.password,
        checkPassword: formMData.value.checkPassword,
        code: formMData.value.code,
        sex: gender.value,
        title: formData.value.title,
        privacy: true
      }
      store.dispatch('register/accountRegisterAction', submitData)
    }

    const popToLoginPage = () => {
      router.back()
    }

    return {
      nextDisabled,
      registerDisabled,
      gender,
      isAgree,
      isModuleOne,
      formData,
      formBData,
      formMData,
      registerFormConfig,
      registerFormBConfig,
      registerFormModule,
      nextAction,
      agreementClick,
      popToLoginPage,
      registerAction
    }
  }
})
